<template>
  <el-container class="pos-r">
    <div class="login-cont">
      <div class="login-wrapper">
        <div class="login_box">
          <div class="lg-content">
            <div class="logo_box t-c">
              <img
                src="@/assets/images/login/login_logo.svg"
                type="image/svg+xml"
                height="45"
                v-if="version === 'common'"
                alt="数知舆情管理系统"
              />
              <img
                src="@/assets/images/login/yt_login_logo.svg"
                type="image/svg+xml"
                height="45"
                v-else-if="version === 'yuantong'"
                alt="圆通情报管理系统"
              />
            </div>
            <div class="form_box pt_20">
              <div class="input_box_item">
                <div class="input_box" id="idInput">
                  <i class="iconfont icon-id"></i>
                  <el-form>
                    <el-form-item>
                      <el-input
                        placeholder="客户ID"
                        v-model.trim="domain"
                        id="domain"
                        @focus="focusInput('idInput')"
                        @blur="blurInput('idInput')"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
                <span class="ts">{{ customer_error_message }}</span>
              </div>
              <div class="input_box_item">
                <div class="input_box" id="usernameInput">
                  <i class="iconfont icon-yonghu"></i>
                  <el-form>
                    <el-form-item>
                      <el-input
                        placeholder="账号"
                        v-model.trim="username"
                        id="username"
                        @focus="focusInput('usernameInput')"
                        @blur="blurInput('usernameInput')"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="input_box_item">
                <div class="input_box" id="pwdInput">
                  <i class="iconfont icon-mima"></i>
                  <el-form>
                    <el-form-item>
                      <el-input
                        placeholder="登录密码"
                        v-model.trim="password"
                        id="password"
                        type="password"
                        @focus="focusInput('pwdInput')"
                        @blur="blurInput('pwdInput')"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
                <span class="ts" v-text="user_error_message"></span>
                <el-tooltip placement="bottom-end">
                  <var>
                    忘记密码
                    <i class="iconfont icon-wenhao"></i>
                  </var>
                  <div slot="content" class="wjmf">
                    <h4><i class="iconfont icon-yonghu"></i> 普通用户：</h4>
                    <p>请与您的系统管理员联系进行密码重置</p>
                    <h4 class="mar-t-10">
                      <i class="iconfont icon-guanliyuan"></i> 系统管理员：
                    </h4>
                    <p>请与您的专属客服联系进行密码重置</p>
                  </div>
                </el-tooltip>
              </div>
              <el-button type="info" round size="medium" id="blogin"
                >登 录</el-button
              >
              <el-button
                type="info"
                round
                size="medium"
                id="login"
                v-loading="loading"
                class="primary hide"
                @click="login"
                >登 录</el-button
              >
              <div class="note-text">
                <span>推荐使用Google Chrome谷歌浏览器</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="http://www.beian.miit.gov.cn" class="copyright" target="_blank"
      >沪ICP备18028315号</a
    >

    <div style="display:none;">
      <img
        src="@/assets/images/login/login_left.svg"
        class="img"
        width="40%"
        type="image/svg+xml"
        v-if="version === 'common'"
      />
      <img
        src="@/assets/images/login/yt_login_left.png"
        class="img"
        width="40%"
        type="image/svg+xml"
        v-else-if="version === 'yuantong'"
      />
      <div class="login_left hide">
        <div class="img"></div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="resetPassDialog.visible"
      width="400px"
      class="dialogVisible6 login-reset"
    >
      <div class="czmm-content">
        <div class="mod-title">
          <span>密码重置</span>
        </div>
        <div class="form_box pt_20">
          <div class="input_box_item">
            <div class="input_box">
              <i class="iconfont icon-yaoshi"></i>
              <el-form>
                <el-form-item>
                  <el-input
                    placeholder="请输入原始密码"
                    type="password"
                    id="oldPass"
                    @input="check('oldError')"
                    v-model="resetPassDialog.old_pass"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <span class="ts">{{ resetPassDialog.oldError }}</span>
          </div>
          <div class="input_box_item">
            <div class="input_box">
              <i class="iconfont icon-mima"></i>
              <el-form>
                <el-form-item>
                  <el-input
                    placeholder="请输入新密码"
                    type="password"
                    id="newPass"
                    @input="check('newError')"
                    v-model.trim="resetPassDialog.new_pass"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <span class="ts">{{ resetPassDialog.newError }}</span>
          </div>
          <div class="input_box_item">
            <div class="input_box">
              <i class="iconfont icon-mima"></i>
              <el-form>
                <el-form-item>
                  <el-input
                    placeholder="请再次输入新密码"
                    type="password"
                    id="repeatPass"
                    @input="check('repeatError')"
                    v-model.trim="resetPassDialog.repeat_pass"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <span class="ts">{{ resetPassDialog.repeatError }}</span>
          </div>
          <el-button
            type="primary"
            round
            size="medium"
            id="beforeLogin"
            :disabled="true"
            >确 定</el-button
          >
          <el-button
            type="primary"
            round
            size="medium"
            id="afterLogin"
            class="primary hide"
            @click="resetPass"
            v-loading="resetLoading"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>
<script type="application/javascript">
import { changePass, auth as userAuth } from '@/api/user';
import { setAuthToken } from '@/utils/helpers';
export default {
  name: 'component-login',
  data() {
    return {
      loading: false,
      resetLoading: false,
      domain: '',
      username: '',
      password: '',
      customer_error_message: '',
      user_error_message: '',
      resetPassDialog: {
        visible: false,
        oldError: '',
        newError: '',
        repeatError: '',
        old_pass: '',
        new_pass: '',
        repeat_pass: ''
      },
      version: process.env.VUE_APP_VERSION
    };
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      this.loginEvent();
    });
  },
  methods: {
    loginEvent() {
      var _this = this;
      var login_box = window.document.getElementsByClassName('login_box')[0];
      window.jQuery(login_box).off('keydown');
      window.jQuery(login_box).keydown(function(event) {
        if (event.keyCode === 13) {
          _this.login();
          return false;
        }
      });
      var reset = window.document.getElementsByClassName('login-reset')[0];
      window.jQuery(reset).off('keydown');
      window.jQuery(reset).keydown(function(event) {
        if (event.keyCode === 13) {
          _this.resetPass();
          return false;
        }
      });
      var check = function() {
        let user = window.document.getElementById('username');
        let domain = window.document.getElementById('domain');
        let password = window.document.getElementById('password');
        _this.user_error_message = '';
        if (
          domain.value.length >= 2 &&
          user.value.length >= 2 &&
          password.value.length >= 6
        ) {
          window.document.getElementById('blogin').classList.add('hide');
          window.document.getElementById('login').classList.remove('hide');
        } else {
          window.document.getElementById('login').classList.add('hide');
          window.document.getElementById('blogin').classList.remove('hide');
        }
      };
      var user = window.document.getElementById('username');
      var domain = window.document.getElementById('domain');
      var password = window.document.getElementById('password');
      window.jQuery(user).off('input');
      window.jQuery(domain).off('input');
      window.jQuery(password).off('input');
      if (user && domain && password) {
        domain.oninput = check;
        user.oninput = check;
        password.oninput = check;
      }
    },
    check(key) {
      this.resetPassDialog[key] = '';
      let oldPass = this.resetPassDialog.old_pass;
      let newPass = this.resetPassDialog.new_pass;
      let repeatPass = this.resetPassDialog.repeat_pass;
      if (
        oldPass.length >= 6 &&
        newPass.length >= 6 &&
        repeatPass.length >= 6
      ) {
        window.document.getElementById('beforeLogin').classList.add('hide');
        window.document.getElementById('afterLogin').classList.remove('hide');
      } else {
        window.document.getElementById('afterLogin').classList.add('hide');
        window.document.getElementById('beforeLogin').classList.remove('hide');
      }
    },
    resetPass() {
      let oldPass = this.resetPassDialog.old_pass;
      let newPass = this.resetPassDialog.new_pass;
      let repeatPass = this.resetPassDialog.repeat_pass;
      if (oldPass.length > 18 || oldPass.length < 6) {
        this.resetPassDialog.oldError = '密码长度6~18位字符串';
        this.$message.warning('密码长度6~18位字符串');
        return false;
      }
      if (repeatPass.length > 18 || repeatPass.length < 6) {
        this.resetPassDialog.repeatError = '新密码长度6~18位字符串';
        this.$message.warning('新密码长度6~18位字符串');
        return false;
      }
      if (repeatPass !== newPass) {
        this.resetPassDialog.repeatError = '两次输入密码不一致，请重新输入';
        this.$message.warning('两次输入密码不一致，请重新输入');
        return false;
      }
      if (this.resetLoading) return false;
      this.resetLoading = true;
      changePass({
        old_pass: oldPass,
        new_pass: newPass,
        repeat_pass: repeatPass
      })
        .then(res => {
          this.resetLoading = false;
          if (res.data.state) {
            this.$message.success('重置密码成功，请重新登陆');
            this.password = '';
            this.resetPassDialog.visible = false;
            this.$store.commit('SET_RESET_TOKEN', null);
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('重置密码失败，服务错误');
        });
    },
    login() {
      if (!this.domain || !this.username || !this.password) {
        this.$message.error('请填写完整的用户信息');
        return false;
      }
      if (this.username.length < 6 || this.username.length > 18) {
        this.user_error_message = '用户名长度6~18位字符串';
        return false;
      }
      if (this.password.length < 6 || this.password.length > 18) {
        this.user_error_message = '密码长度6~18位字符串';
        return false;
      }
      if (this.loading) return false;
      this.loading = true;
      userAuth({
        domain: this.domain,
        username: this.username,
        password: this.password,
        host: window.location.host
      })
        .then(res => {
          this.loading = false;
          if (res.data.state) {
            if (
              res.data.hasOwnProperty('data') &&
              res.data.data.hasOwnProperty('change_password')
            ) {
              const { token } = res.data.data;
              // 重置密码token
              this.$store.commit('SET_RESET_TOKEN', token);
              this.$message.warning('第一次登陆需要重置密码');
              this.resetPassDialog = {
                visible: true,
                oldError: '',
                newError: '',
                repeatError: '',
                old_pass: '',
                new_pass: '',
                repeat_pass: ''
              };
            } else {
              const { token } = res.data.data;
              setAuthToken(token);
              window.location.href = '/yq';
            }
          } else {
            this.user_error_message = res.data.error;
          }
        })
        .catch(res => {
          try {
            if (res.response.status == 302) {
              window.location.href = '/';
            }
          } catch (err) {
            window.console.error(err);
          }
          this.loading = false;
          this.$message.error('登陆服务错误');
        });
    },
    focusInput(id) {
      window.jQuery(`#${id}`).addClass('active');
    },
    blurInput(id) {
      window.jQuery(`#${id}`).removeClass('active');
    }
  }
};
</script>
<style scoped>
.el-container {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-image: url(../../assets/images/login/yuantong-login-bg.png);
  display: flex;
  flex-direction: column;
}
.login-cont {
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
}
.login-wrapper {
  width: 920px;
  height: 520px;
  background-image: url(../../assets/images/login/yuantong-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin-top: 30px;
}
.login_box {
  float: right;
  margin-right: 80px;
  margin-top: 35px;
}
.lg-content {
  position: relative;
  width: 340px;
  height: 450px;
}
.logo_box {
  padding: 15px 0;
}
.form_box {
  margin: 20px 0;
}
.input_box_item {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.input_box {
  border: solid 1px #d2d2d2;
  border-radius: 28px;
  display: flex;
  font-size: 14px;
  position: relative;
  margin-bottom: 35px;
  line-height: 45px;
}
.input_box.active {
  border-color: #4e88fe;
  background: #fff;
}
.input_box .iconfont {
  font-size: 20px;
  margin: 0 8px 0 30px;
}
.input_box.active .iconfont {
  color: #4e88fe;
}
.input_box .el-form {
  width: 250px;
  line-height: 45px;
  font-size: 16px;
}
.login_box .el-form-item--small.el-form-item {
  margin-bottom: 0;
  line-height: 45px;
  margin-top: 0;
}
.login_box >>> .el-form-item__content {
  line-height: 45px;
}
.input_box >>> .el-input__inner {
  width: 100%;
  border: none;
  padding-left: 5px;
  font-size: 14px;
  background: none;
  height: 45px;
  line-height: 45px;
}
.input_box_item span,
.input_box_item var {
  position: absolute;
  z-index: 5;
  color: red;
  bottom: 10px;
  font-size: 14px;
}
.input_box_item span {
  left: 30px;
}
.input_box_item var {
  color: #7c7c7c;
  font-style: normal;
  right: 0;
  bottom: 3px;
}
.lg-content .el-button,
.el-button.primary {
  width: 100%;
  margin: 25px 0 0;
  border: none;
  color: #fff;
  line-height: 45px;
  height: 45px;
  border-radius: 28px;
  padding: 0;
  background: linear-gradient(
    90deg,
    rgba(93, 101, 250, 1),
    rgba(102, 97, 254, 1)
  );
  font-size: 14px;
}
.copyright {
  color: #333;
  text-align: center;
  line-height: 60px;
  font-size: 14px;
  display: block;
}
.copyright:hover,
.copyright:active {
  color: #333;
}
/*重置密码弹出框*/
.dialogVisible6 >>> .el-dialog {
  background: none;
  box-shadow: none;
}
.dialogVisible6 >>> .el-dialog__header {
  display: none;
}
.czmm-content {
  position: relative;
  background: #fff;
  border-radius: 0.5em;
  width: 100%;
  height: 450px;
}
.dialogVisible6 .mod-title {
  border-bottom: 1px solid rgba(8, 35, 83, 0.09);
  font-size: 14px;
  padding: 23px 0;
  border-left: none;
  margin-left: 0;
}
.dialogVisible6 .mod-title span {
  margin-left: 25px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  height: 14px;
}
.dialogVisible6 .form_box {
  margin: 40px;
}
.dialogVisible6 .input_box_item {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.dialogVisible6 .input_box {
  border: solid 1px #d2d2d2;
  border-radius: 6px;
  display: flex;
  padding: 0 0.5em;
  font-size: 14px;
  position: relative;
  margin-bottom: 35px;
  line-height: 45px;
}
.dialogVisible6 .input_box img {
  margin: 0 5px;
}
.dialogVisible6 .input_box .el-form {
  width: 100%;
  line-height: 45px;
}
.dialogVisible6 .el-form-item--small.el-form-item {
  margin-bottom: 0;
  line-height: 45px;
  margin-top: 0;
}
.dialogVisible6 .input_box_item span {
  position: absolute;
  right: 0;
  z-index: 5;
  color: red;
  bottom: 0;
}
.dialogVisible6 .el-button {
  width: 100%;
  margin: 10px 0;
  line-height: 40px;
  height: 40px;
  padding: 0;
  font-size: 14px;
}
.dialogVisible6 .el-button.primary {
  background: linear-gradient(
    90deg,
    rgba(93, 101, 250, 1),
    rgba(102, 97, 254, 1)
  );
}
.dialogVisible6 .input_box .el-input__inner {
  width: 100%;
  border: none;
  padding-left: 5px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  background: #fff;
}
.login_box >>> .el-loading-mask,
.dialogVisible6 >>> .el-loading-mask {
  border-radius: 30px;
}
.dialogVisible6 >>> .el-input__inner {
  height: 45px;
  line-height: 45px;
  font-size: 14px;
}
.dialogVisible6 .iconfont {
  font-size: 18px;
  margin: 0 10px;
}
/*忘记密码*/
.input_box_item .el-tooltip:hover {
  color: rgb(42, 103, 179);
  cursor: pointer;
}
.wjmf {
  line-height: 28px;
}
.wjmf h4 {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
}
.wjmf p {
  color: #fff;
}
.wjmf .iconfont {
  font-size: 12px;
  color: #fff;
}
.note-text {
  color: #adb5c7;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  margin-top: 10px;
}
</style>
