<template>
    <el-container>
        <military-login v-if="version === 'military'" />
        <yuantong-login v-else-if="version === 'yuantong'" />
        <shwj-login v-else-if="version === 'shwj'" />
        <common-login v-else />
    </el-container>
</template>
<script type="application/javascript">
  import Login from './login.vue'
  import YuantongLogin from './yuantong-login.vue'
  import MilitaryLogin from './military-login.vue'
  import ShwjLogin from './shwj-login.vue'
  export default {
    name: "component-login",
    components: {
      "common-login": Login,
      ShwjLogin,
      YuantongLogin,
      MilitaryLogin
    },
    data() {
      return {
        version: process.env.VUE_APP_VERSION
      }
    },
    beforeCreate() {
    },
    mounted() {
      if (this.$route.query.hasOwnProperty('code')) {
        const code = parseInt(this.$route.query.code)
        if (code === 10) {
          this.$message({
            type: 'error',
            message: '您的账号已在异地登录。',
            duration: 6000
          })
        } else if ([8, 9].indexOf(code) !== -1) {
          this.$message({
            type: 'error',
            message: '您的登录认证已失效，请重新登录。',
            duration: 6000
          })
        }
      }
    },
    methods: {
      loginEvent() {
      }
    }
  }
</script>
<style scoped>
    .el-container{height: 100%;width: 100%;}
</style>
